import React from 'react';
import styled from 'styled-components';

const Logo = ({light}) => {
  return (
    <LogoContainer>
        <Image src={light ? "/antibionic-simple-light.png" : "/antibionic-simple-dark.png"} alt="App Logo" />
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
    height: 30px;
`;

const Image = styled.img`
    height: 100%;
`