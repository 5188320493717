import React from "react";
import { ResponsiveLine } from "@nivo/line";

const theme = {
  background: "#333",
  text: {
    fontSize: 12,
    fill: "#fff",
    outlineWidth: 0,
    outlineColor: "transparent",
  },
  axis: {
    domain: {
      line: {
        stroke: "#fff",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#fff",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    ticks: {
      line: {
        stroke: "#fff",
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fill: "#fff",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  grid: {
    line: {
      stroke: "#ccc",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 12,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    text: {
      fontSize: 12,
      fill: "#333333",
      outlineWidth: 0,
      outlineColor: "transparent",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 12,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#fff",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#fff",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#fff",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    wrapper: {},
    container: {
      background: "#444",
      color: "#fff",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

const LineChart = ({ active }) => {
  const twitterData = [
    {
      x: "11/26",
      y: 78,
    },
    {
      x: "11/27",
      y: 23,
    },
    {
      x: "11/28",
      y: 45,
    },
    {
      x: "11/29",
      y: 92,
    },
    {
      x: "11/30",
      y: 17,
    },
    {
      x: "12/01",
      y: 63,
    },
    {
      x: "12/02",
      y: 31,
    },
    {
      x: "12/03",
      y: 88,
    },
    {
      x: "12/04",
      y: 5,
    },
    {
      x: "12/05",
      y: 99,
    },
    {
      x: "12/06",
      y: 27,
    },
    {
      x: "12/07",
      y: 71,
    },
    {
      x: "12/08",
      y: 42,
    },
  ];

  const redditData = [
    { x: "11/26", y: 37 },
    { x: "11/27", y: 63 },
    { x: "11/28", y: 92 },
    { x: "11/29", y: 58 },
    { x: "11/30", y: 15 },
    { x: "12/01", y: 82 },
    { x: "12/02", y: 4 },
    { x: "12/03", y: 90 },
    { x: "12/04", y: 21 },
    { x: "12/05", y: 11 },
    { x: "12/06", y: 78 },
    { x: "12/07", y: 14 },
    { x: "12/08", y: 38 },
  ];

  const linkedinData = [
    { x: "11/26", y: 91 },
    { x: "11/27", y: 37 },
    { x: "11/28", y: 16 },
    { x: "11/29", y: 80 },
    { x: "11/30", y: 2 },
    { x: "12/01", y: 41 },
    { x: "12/02", y: 95 },
    { x: "12/03", y: 19 },
    { x: "12/04", y: 60 },
    { x: "12/05", y: 44 },
    { x: "12/06", y: 22 },
    { x: "12/07", y: 9 },
    { x: "12/08", y: 59 },
  ];

  return (
    <ResponsiveLine
      data={[
        {
          id: "data",
          data:
            active == "Twitter"
              ? twitterData
              : active == "Reddit"
              ? redditData
              : linkedinData,
        },
      ]}
      margin={{ top: 20, right: 30, bottom: 50, left: 30 }}
      xScale={{ type: "point" }}
      yScale={{ type: "linear", min: 0, max: 100 }}
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendOffset: 40,
        legendPosition: "middle",
      }}
      colors={["#fff"]}
      axisLeft={null}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      theme={theme}
    />
  );
};

export default LineChart;
