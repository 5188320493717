import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "../../context/ModalContext";

const AddUserModal = ({ addUser }) => {
  const { closeModal } = useModal();

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email) {
      setError("All fields are required.");
      return;
    }
    // Here you would typically send the invite (e.g., API call)
    console.log("Inviting user:", { firstName, lastName, email });
    addUser(firstName, lastName, email);
    closeModal();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />

      <Input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      <Input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      {/* Error Message */}
      {error && <ErrorText>{error}</ErrorText>}

      {/* Submit Button */}
      <Button type="submit">Send Invitation</Button>
    </Form>
  );
};

// Styled Components

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  text-align: center;
`;

const Button = styled.button`
  padding: 12px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

export default AddUserModal;
