import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import OverviewTab from "../components/Tabs/OverviewTab";
import AccountsTab from "../components/Tabs/AccountsTab";
import NarrativesTab from "../components/Tabs/NarrativesTab";

const tabs = [
  {
    title: "Overview",
    icon: faHouse,
    tab: <OverviewTab />,
  },
  {
    title: "Top Accounts",
    icon: faUsers,
    tab: <AccountsTab />,
  },
  {
    title: "Narratives",
    icon: faHashtag,
    tab: <NarrativesTab />,
  },
  {
    title: "Bot Activity",
    icon: faRobot,
  },
  {
    title: "Generate Report",
    icon: faNoteSticky,
  },
];

const TopicPage = () => {
  const [selected, setSelected] = useState(0);
  return (
    <PageContainer>
      <TabsContainer>
        {tabs.map((item, index) => (
          <Tab selected={selected == index} onClick={() => setSelected(index)}>
            <TabIcon icon={item.icon} />
            <TabText>{item.title}</TabText>
          </Tab>
        ))}
      </TabsContainer>
      {tabs[selected].tab}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const TabsContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  width: 30px;
`;

const Tab = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  transition: 0.2s ease;
  cursor: pointer;
  border: 1px solid #ccc;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const TabText = styled.div`
  padding-top: 4px;
  font-size: 16px;
`;

export default TopicPage;
