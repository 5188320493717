import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "../../context/ModalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareReddit } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

const socialTabs = [
  {
    title: "Twitter",
    icon: faSquareTwitter,
    suggested: [
      { name: "@POTUS", type: "user" },
      {
        name: "@VP",
        type: "user",
      },
      {
        name: "@WhiteHouse",
        type: "user",
      },
    ],
  },
  {
    title: "Reddit",
    icon: faSquareReddit,
    suggested: [
      { name: "r/politics", type: "community" },
      {
        name: "r/conservative",
        type: "community",
      },
      {
        name: "r/news",
        type: "community",
      },
    ],
  },
  {
    title: "LinkedIn",
    icon: faLinkedin,
    suggested: [
      { name: "The White House", type: "organization" },
      {
        name: "U.S. Congress",
        type: "organization",
      },
      {
        name: "United States Department of Defense",
        type: "organization",
      },
    ],
  },
];

const AddUserGroupModal = () => {
  const { closeModal } = useModal();
  const [groupName, setGroupName] = useState(null);
  const [selected, setSelected] = useState(0);
  const [trackedItems, setTrackedItems] = useState([]);

  const handleAddItem = (text) => {
    setTrackedItems((trackedItems) => [...trackedItems, text]);
  };

  return (
    <Form>
      <Input
        placeholder="Group Name"
        value="The White House"
        onChange={(e) => setGroupName(e.target.value)}
      />

      <SocialsTabs>
        {socialTabs.map((item, index) => (
          <SocialTab
            selected={selected == index}
            onClick={() => setSelected(index)}
          >
            <TabIcon icon={item.icon} />
            <TabText>{item.title}</TabText>
          </SocialTab>
        ))}
      </SocialsTabs>

      <Input
        placeholder={
          "Search " +
          socialTabs[selected].title +
          (socialTabs[selected].title == "Reddit" ? " Communities" : " Users")
        }
        onChange={(e) => setGroupName(e.target.value)}
      />

      <FormSubheader>
        {"Suggested " +
          socialTabs[selected].title +
          (socialTabs[selected].title == "Reddit" ? " Communities" : " Users")}
      </FormSubheader>
      {socialTabs[selected].suggested.map((item, index) => (
        <SuggestionRow>
          <FormText>{item.name + " (" + item.type + ")"}</FormText>
          <SuggestionButton
            icon={faSquarePlus}
            onClick={() => handleAddItem(item.name)}
          />
        </SuggestionRow>
      ))}
      {trackedItems.length ? (
        <>
          <FormSubheader>Tracking...</FormSubheader>

          <FormText>{trackedItems.join(", ")}</FormText>

          <Button>Add New User Group</Button>
        </>
      ) : null}
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SocialsTabs = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SocialTab = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  transition: 0.2s ease;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const TabText = styled.div`
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-bottom: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const FormSubheader = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 16px;
`;

const SuggestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormText = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 12px;
`;

const SuggestionButton = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.typography.color};
  font-size: 16px;
  cursor: pointer;
`;

export default AddUserGroupModal;
