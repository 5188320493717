import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import DashboardPage from "./pages/DashboardPage";
import Navbar from "./components/Navbar";
import TopicPage from "./pages/TopicPage";
import GlobalStyle from "./GlobalStyle";
import { ModalProvider, useModal } from "./context/ModalContext";
import BaseModal from "./components/Modals/BaseModal";
import AccountSettingsPage from "./pages/AccountSettingsPage";

const PageContainer = ({ children }) => {
  const { isOpen, modalComponent, closeModal, modalTitle } = useModal();

  return (
    <PageStyle>
      <Navbar />
      {children}

      <BaseModal isOpen={isOpen} onClose={closeModal} title={modalTitle}>
        {modalComponent && modalComponent}
      </BaseModal>
    </PageStyle>
  );
};

const App = () => {
  return (
    <ModalProvider>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<PageContainer children={<LoginPage />} />}
          />
          <Route
            path="/register"
            element={<PageContainer children={<RegisterPage />} />}
          />
          <Route
            path="/dashboard"
            element={<PageContainer children={<DashboardPage />} />}
          />
          <Route
            path="/topic"
            element={<PageContainer children={<TopicPage />} />}
          />

          <Route
            path="/settings"
            element={<PageContainer children={<AccountSettingsPage />} />}
          />
        </Routes>
      </Router>
    </ModalProvider>
  );
};

const PageStyle = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.background};
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  padding-top: 80px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 40px;
`;

export default App;
