import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../context/ModalContext";
import AddUserGroupModal from "../components/Modals/AddUserGroupModal";
import { faArrowTrendDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const newTopics = [
  {
    title: "Covid-19",
    newPosts: 452,
    activity: 31,
    sentiment: "Negative",
    newTrackedPosts: 5,
  },
  {
    title: "Artificial Intelligence",
    newPosts: 234,
    activity: -12,
    sentiment: "Positive",
    newTrackedPosts: 11,
  },
  {
    title: "Cryptocurrency",
    newPosts: 316,
    activity: 5,
    sentiment: "Mixed",
    newTrackedPosts: 14,
  },
  {
    title: "Ukraine",
    newPosts: 514,
    activity: 36,
    sentiment: "Mixed",
    newTrackedPosts: 26,
  },
  {
    title: "White House",
    newPosts: 416,
    activity: 22,
    sentiment: "Negative",
    newTrackedPosts: 38,
  },
  {
    title: "United Nations",
    newPosts: 126,
    activity: -15,
    sentiment: "Mixed",
    newTrackedPosts: 19,
  },
];

const DashboardPage = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleOpenGroupModal = () => {
    openModal(<AddUserGroupModal />, "Track New Topic");
  };

  return (
    <DashboardContainer>
      <DashboardContainer>
        <DashboardHeader>
          <PageSubheader>Tracked Topics</PageSubheader>
          <Button onClick={handleOpenGroupModal}>Add New Topic</Button>
        </DashboardHeader>

        <ItemGrid>
          {newTopics.map((item, index) => (
            <TopicCard
              onClick={() => {
                navigate("/topic");
              }}
            >
              <CardHeader>
                <CardTitle>{item.title}</CardTitle>
                <CardBadge activity={item.activity}>
                  <FontAwesomeIcon
                    icon={item.activity > 0 ? faArrowTrendUp : faArrowTrendDown}
                  />{" "}
                  {item.activity}%
                </CardBadge>
              </CardHeader>
              <CardBody>
                <CardInfoContainer>
                  <CardSubtitle>New Posts (24h)</CardSubtitle>
                  <CardInfoRow>
                    <FontAwesomeIcon icon={faMessage} size="xl" />
                    <CardInfo>{item.newPosts}</CardInfo>
                  </CardInfoRow>
                </CardInfoContainer>
                <CardInfoContainer>
                  <CardSubtitle>Sentiment</CardSubtitle>
                  <CardInfoRow sentiment={item.sentiment}>
                    <FontAwesomeIcon icon={faArrowTrendUp} size="xl" />
                    <CardInfo>{item.sentiment}</CardInfo>
                  </CardInfoRow>
                </CardInfoContainer>
              </CardBody>
              <CardSubtitle>
                {item.newTrackedPosts} new posts from followed users
              </CardSubtitle>
            </TopicCard>
          ))}
        </ItemGrid>
      </DashboardContainer>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageHeader = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 36px;
  font-weight: 800;
`;

const PageSubheader = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-weight: 600;
  font-size: 24px;
`;

const ItemGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
`;

const TopicCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: ${(props) => props.theme.typography.color};
  width: 380px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0.5;
`;
const CardInfoRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${(props) =>
    props.sentiment
      ? props.sentiment == "Positive"
        ? props.theme.colors.green
        : props.sentiment == "Mixed"
        ? props.theme.colors.yellow
        : props.theme.colors.red
      : "#fff"};
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
`;

const CardSubtitle = styled.div`
  font-size: 14px;
  color: #ccc;
`;

const CardInfo = styled.div`
  font-size: 24px;
  font-weight: 800;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
  color: ${(props) => props.theme.typography.color};
  border-radius: 20px;
  width: 80px;
  padding: 5px;
  background-color: ${(props) =>
    props.activity > 0 ? props.theme.colors.green : props.theme.colors.red};
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-bottom: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

export default DashboardPage;
