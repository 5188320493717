import React, { useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${(props) => props.theme.colors.background};
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  position: relative;
  padding-top: 40px;
  color: ${(props) => props.theme.typography.color};
`;

const ModalCloseButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  border: none;
  background: none;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  padding-bottom: 20px;
`;

const BaseModal = ({ isOpen, title, onClose, children }) => {
  useEffect(() => {
    // Add event listener to close modal when clicking outside
    if (isOpen) {
      console.log(title);
      const handleClickOutside = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
          onClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null; // Don't render modal if not open

  return (
    <ModalOverlay className="modal-overlay">
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton icon={faXmark} onClick={onClose} />
        {children} {/* Render the dynamic modal content */}
      </ModalContent>
    </ModalOverlay>
  );
};

export default BaseModal;
