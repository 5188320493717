// ModalContext.js
import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const openModal = (Component, title) => {
    setModalComponent(() => Component);
    setModalTitle(title);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalComponent(null);
  };

  return (
    <ModalContext.Provider
      value={{ isOpen, modalComponent, modalTitle, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
