export const theme = {
  colors: {
    primary: "#7957D5",
    secondary: "#3F51B5",
    text: "#363636",
    background: "#222",
    backgroundSecondary: "#333",
    red: "#D61F1F",
    yellow: "#FFD301",
    green: "#3a8c23",
  },
  typography: {
    fontSize: "16px",
    color: "#fff",
  },
  spacing: {
    small: "8px",
    medium: "16px",
    large: "32px",
  },
  borderRadius: "8px",
};
