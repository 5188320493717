import React from "react";
import { useState } from "react";
import styled from "styled-components";
import LineChart from "../LineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareReddit } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons";

const socialTabs = [
  {
    title: "Twitter",
    icon: faSquareTwitter,
  },
  {
    title: "Reddit",
    icon: faSquareReddit,
  },
  {
    title: "LinkedIn",
    icon: faLinkedin,
  },
];

const twitterAccounts = {
  pinnedAccounts: [
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1572512818796335105/YF_H68EL_400x400.jpg", // World Health Organization (WHO)
      account: "@WHO",
      name: "World Health Organization",
      followers: "878,0000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1775519665390772225/clcxou-0_400x400.jpg", // Centers for Disease Control and Prevention (CDC)
      account: "@CDCgov",
      name: "Centers for Disease Control and Prevention",
      followers: "4,480,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1783621313380200448/YiMaUHu9_400x400.jpg", // Anthony Fauci (Director of NIAID)
      account: "@Fauci",
      name: "Anthony Fauci",
      followers: "2,520,000", // Approx. followers
    },
  ],
  officialAccounts: [
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1286024647171956736/bJ4lH6YI_400x400.png", // COVID-19 Tracking Project
      account: "@COVID19Tracking",
      name: "COVID-19 Tracking Project",
      followers: "35,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1417222610803298312/8Pro0a_r_400x400.jpg", // John Hopkins University
      account: "@HopkinsMedicine",
      name: "Johns Hopkins University",
      followers: "1,300,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1375464964769595399/4g7rQNme_400x400.jpg", // National Institutes of Health (NIH)
      account: "@NIH",
      name: "National Institutes of Health",
      followers: "1,100,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/808330362417979392/AdiQ86lk_400x400.jpg", // UNICEF
      account: "@UNICEF",
      name: "UNICEF",
      followers: "1,700,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/791269606996443136/9oft8kxO_400x400.jpg", // ECDC (European Centre for Disease Prevention and Control)
      account: "@ECDC_EU",
      name: "European Centre for Disease Prevention and Control",
      followers: "15,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1543928698373656576/A3uVPpS3_400x400.jpg", // GAVI (The Vaccine Alliance)
      account: "@Gavi",
      name: "The Vaccine Alliance",
      followers: "135,000", // Approx. followers
    },
  ],
  topAccountsByEngagement: [
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1674815862879178752/nTGMV1Eo_400x400.jpg", // Bill Gates
      account: "@BillGates",
      name: "Bill Gates",
      followers: "61,700,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/378800000725668351/16bf102fc319d621a456d9f821071253_400x400.jpeg", // The Lancet
      account: "@TheLancet",
      name: "The Lancet",
      followers: "93,000", // Approx. followers
    },
    {
      profile_picture:
        "https://pbs.twimg.com/profile_images/1356375837189267459/BiWCOJIC_400x400.jpg", // Dr. Maria Van Kerkhove
      account: "@mvankerkhove",
      name: "Dr. Maria Van Kerkhove",
      followers: "200,000", // Approx. followers
    },
  ],
};

const redditCommunities = {
  pinnedCommunities: [
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_2f4l19/styles/communityIcon_s9svsk6xmcg41.jpg?format=pjpg&s=37b00e5b88316df591e08cfdc20da1dff0ca3a68", // World Health Organization (WHO)
      account: "r/COVID19",
      name: "COVID-19",
      followers: "575,000", // Approx. followers
    },
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_2x4yx/styles/communityIcon_ex5aikhvi3i41.png", // Centers for Disease Control and Prevention (CDC)
      account: "r/Coronavirus",
      name: "Novel Coronavirus (COVID-19)",
      followers: "2,600,000", // Approx. followers
    },
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_2vw5a0/styles/communityIcon_e8dx5awmax271.jpg?format=pjpg&s=742583a2f612cfe5fa35e6b619859f2ada482318", // Anthony Fauci (Director of NIAID)
      account: "r/longcovid",
      name: "LongCovid",
      followers: "27,000", // Approx. followers
    },
  ],
  topCommunitiesByEngagement: [
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_2qh4r/styles/communityIcon_njjmedw5gmv41.jpg?format=pjpg&s=6c7414f291aa2c60c8c4d085f7f0da8da0b4f065", // Bill Gates
      account: "r/conspiracy",
      name: "conspiracy",
      followers: "2,200,000", // Approx. followers
    },
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_2qh3l/styles/communityIcon_fmygcobc22z81.png", // The Lancet
      account: "r/news",
      name: "News",
      followers: "29,000,000", // Approx. followers
    },
    {
      profile_picture:
        "https://styles.redditmedia.com/t5_35e6ns/styles/communityIcon_ted7mx6uqj881.png", // Bill Gates
      account: "r/HermanCainAward",
      name: "Awarded… posthumously.",
      followers: "485,000",
    },
  ],
};

const linkedInData = {
  topOrganizations: [
    {
      profile_picture:
        "https://media.licdn.com/dms/image/v2/C560BAQEhC1u0R5DRQQ/company-logo_200_200/company-logo_200_200/0/1663777945634/world_health_organization_logo?e=1741219200&v=beta&t=qgm6Z7HNJ85cJqG6DFsJDOAfpvEvL-9Ge7-15VZrJDw",
      account: "World Health Organization",
      followers: "6,000,000",
    },
    {
      profile_picture:
        "https://media.licdn.com/dms/image/v2/D4E0BAQEaXQko6Y3k1w/company-logo_200_200/company-logo_200_200/0/1712151927475/centers_for_disease_control_and_prevention_logo?e=1741219200&v=beta&t=gAkyOP6e0qi-qJ4mB84C41LeyfS4rbgdiPSIvoxbvdc",
      account: "Centers for Disease Control and Prevention",
      followers: "2,000,000",
    },
    {
      profile_picture:
        "https://media.licdn.com/dms/image/v2/C4E0BAQEr7et8v1YdJQ/company-logo_200_200/company-logo_200_200/0/1654281016074/us_department_of_health_and_human_services_logo?e=1741219200&v=beta&t=HLz_yqlSClfHvOlPeVbDuZ05g01DDBwSi13fAEw22Bg",
      account: "U.S. Department of Health and Human Services (HHS)",
      followers: "777,000",
    },
  ],
  topAccounts: [
    {
      profile_picture:
        "https://media.licdn.com/dms/image/v2/D5603AQHv6LsdiUg1kw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1695167344576?e=1738800000&v=beta&t=CaB3kxg86snUPOXGSG4psKBFW8KKVoRHonM6jHgNMGg",
      account: "Bill Gates",
      followers: "36,000,000",
    },
  ],
};

const AccountsTab = () => {
  const [selected, setSelected] = useState(0);
  return (
    <TabContainer>
      <HeaderText>Top Accounts for Covid-19</HeaderText>
      <SocialsTabs>
        {socialTabs.map((item, index) => (
          <SocialTab
            selected={selected == index}
            onClick={() => setSelected(index)}
          >
            <TabIcon icon={item.icon} />
            <TabText>{item.title}</TabText>
          </SocialTab>
        ))}
      </SocialsTabs>
      {socialTabs[selected].title == "Twitter" ? (
        <>
          <SubheaderText>Pinned Accounts</SubheaderText>
          <AccountGrid>
            {twitterAccounts.pinnedAccounts.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.name}</AccountTitle>
                <AccountHandle>{item.account}</AccountHandle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
          <SubheaderText>Official Accounts</SubheaderText>
          <AccountGrid>
            {twitterAccounts.officialAccounts.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.name}</AccountTitle>
                <AccountHandle>{item.account}</AccountHandle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
          <SubheaderText>Top Accounts by Engagement</SubheaderText>
          <AccountGrid>
            {twitterAccounts.topAccountsByEngagement.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.name}</AccountTitle>
                <AccountHandle>{item.account}</AccountHandle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
        </>
      ) : socialTabs[selected].title == "Reddit" ? (
        <>
          <SubheaderText>Pinned Communities</SubheaderText>
          <AccountGrid>
            {redditCommunities.pinnedCommunities.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.name}</AccountTitle>
                <AccountHandle>{item.account}</AccountHandle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
          <SubheaderText>Top Communities by Engagement</SubheaderText>
          <AccountGrid>
            {redditCommunities.topCommunitiesByEngagement.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.name}</AccountTitle>
                <AccountHandle>{item.account}</AccountHandle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
        </>
      ) : (
        <>
          <SubheaderText>Top Organizations by Engagement</SubheaderText>

          <AccountGrid>
            {linkedInData.topOrganizations.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.account}</AccountTitle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
          <SubheaderText>Top Users by Engagement</SubheaderText>
          <AccountGrid>
            {linkedInData.topAccounts.map((item, index) => (
              <AccountContainer>
                <AccountIcon image={item.profile_picture} />
                <AccountTitle>{item.account}</AccountTitle>
                <AccountInfo>{item.followers} followers</AccountInfo>
              </AccountContainer>
            ))}
          </AccountGrid>
        </>
      )}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const SubheaderText = styled.div`
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.typography.color};
`;

const SocialsTabs = styled.div`
  display: flex;
  gap: 10px;
`;

const SocialTab = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  transition: 0.2s ease;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const TabText = styled.div`
  font-size: 16px;
`;

const AccountContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 300px;
  padding: 10px;
  justify-content: center;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const AccountIcon = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-image: url("${(props) => (props.image ? props.image : null)}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const AccountGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

const AccountTitle = styled.div`
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const AccountHandle = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.typography.color};
  font-size: 12px;
`;

const AccountInfo = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.typography.color};
`;

export default AccountsTab;
