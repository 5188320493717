import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);

  const notificationsRef = useRef(null);
  const accountSettingsRef = useRef(null);

  const toggleNotificationsMenu = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    if (isAccountSettingsOpen) setIsAccountSettingsOpen(false);
  };

  const toggleAccountSettingsMenu = () => {
    setIsAccountSettingsOpen(!isAccountSettingsOpen);
    if (isNotificationsOpen) setIsNotificationsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setIsNotificationsOpen(false);
      } else if (
        accountSettingsRef.current &&
        !accountSettingsRef.current.contains(event.target)
      ) {
        setIsAccountSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <NavbarContainer>
      <StyledLink to="/dashboard">
        <LogoWrapper>
          <Logo light />
          <TextContainer>Antibionic</TextContainer>
        </LogoWrapper>
      </StyledLink>

      <IconsContainer>
        <IconWrapper onClick={toggleNotificationsMenu}>
          <FontAwesomeIcon icon={faBell} />
          {isNotificationsOpen && (
            <DropdownMenu ref={notificationsRef}>
              <DropdownItem>
                Unusual activity for tracked topic: Covid-19
              </DropdownItem>
              <DropdownItem>
                3 new trending hashtags for topic: Election 2024
              </DropdownItem>
              <DropdownItem>
                42 new posts from tracked users in group: Congress
              </DropdownItem>
            </DropdownMenu>
          )}
        </IconWrapper>

        <IconWrapper onClick={toggleAccountSettingsMenu}>
          <FontAwesomeIcon icon={faCog} />
          {isAccountSettingsOpen && (
            <DropdownMenu ref={accountSettingsRef}>
              <StyledLink to="/settings">
                <DropdownItem>Account Settings</DropdownItem>
              </StyledLink>
              <StyledLink to="/">
                <DropdownItem>Log Out</DropdownItem>
              </StyledLink>
            </DropdownMenu>
          )}
        </IconWrapper>
      </IconsContainer>
    </NavbarContainer>
  );
};

const NavbarContainer = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  height: 50px;
  display: flex;
  padding: 0 10%;
  align-items: center;
  gap: 10px;
  z-index: 255;
  border-bottom: 1px solid #ccc;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TextContainer = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;

const IconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 20px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px !important;
  border: 1px solid #ccc;
  width: 200px;
  z-index: 256;
  overflow: hidden;
`;

const DropdownItem = styled.div`
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export default Navbar;
