import React from "react";
import { useState } from "react";
import styled from "styled-components";
import LineChart from "../LineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareReddit } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons";

const socialTabs = [
  {
    title: "Twitter",
    icon: faSquareTwitter,
  },
  {
    title: "Reddit",
    icon: faSquareReddit,
  },
  {
    title: "LinkedIn",
    icon: faLinkedin,
  },
];

const OverviewTab = () => {
  const [selected, setSelected] = useState(0);
  return (
    <TabContainer>
      <HeaderText>Covid-19 Overview</HeaderText>
      <SocialsTabs>
        {socialTabs.map((item, index) => (
          <SocialTab
            selected={selected == index}
            onClick={() => setSelected(index)}
          >
            <TabIcon icon={item.icon} />
            <TabText>{item.title}</TabText>
          </SocialTab>
        ))}
      </SocialsTabs>
      <SubheaderText>Recent Activity</SubheaderText>
      <ChartContainer>
        <ChartHeader>{socialTabs[selected].title} Posts Per Day</ChartHeader>
        <LineChart active={socialTabs[selected].title} />
      </ChartContainer>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const SubheaderText = styled.div`
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.typography.color};
`;

const SocialsTabs = styled.div`
  display: flex;
  gap: 10px;
`;
const SocialTab = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  transition: 0.2s ease;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const ChartContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 300px;
  color: ${(props) => props.theme.typography.color};
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
`;

const ChartHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const TabText = styled.div`
  font-size: 16px;
`;

export default OverviewTab;
