import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareReddit } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCaretDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";

const socialTabs = [
  {
    title: "Twitter",
    icon: faSquareTwitter,
  },
  {
    title: "Reddit",
    icon: faSquareReddit,
  },
  {
    title: "LinkedIn",
    icon: faLinkedin,
  },
];

const topTwitterPosts = [
  {
    profile_picture:
      "https://pbs.twimg.com/profile_images/1706201897512865792/LItqp-Mt_400x400.jpg",
    account: "@MakisMD",
    name: "William Makis MD",
    post: 'Pfizer and Moderna COVID-19 mRNA Vaccines are extremely dangerous in pregnancy.\n\nThey cause skyrocketing miscarriages, stillbirths and sudden deaths of pregnant women & babies.\n\nYou don\'t share "immune protection" with your newborn - only fatal spike proteins.',
    likes: "1.5k",
    retweets: "907",
    date: "Nov 25, 2024",
  },
  {
    profile_picture:
      "https://pbs.twimg.com/profile_images/1732205616566013952/PmE5KFNf_400x400.jpg",
    account: "@eddyburback",
    name: "Eddy Burback",
    post: 'BREAKING: CDC announces that if you work at dominos, COVID-19 "doesn’t count"',
    likes: "51k",
    retweets: "2.4k",
    date: "Dec 28, 2021",
  },
  {
    profile_picture:
      "https://pbs.twimg.com/profile_images/1683082728986800131/Xz8L43PW_400x400.jpg",
    account: "@CovidCaution",
    name: "Covid Caution - KP.2, KP.2.3, KP.3.1.1, LB.1, XEC",
    post: "Whooping cough surging in California.\n\nWalking pneumonia surging everywhere.\n\nPeople’s lungs and bodies are weakened by COVID-19 and are susceptible to opportunistic infections.\n\nThis isn’t speculation, this is what thousands of scientific studies have warned us about for years.",
    likes: "1.4k",
    retweets: "612",
    date: "Nov 22, 2024",
  },
];

const topHashtags = [
  {
    hashtag: "#DiedSuddenly",
    posts: "3k tweets",
  },
  {
    hashtag: "#publichealth",
    posts: "1.2k tweets",
  },
  {
    hashtag: "#ivermectin",
    posts: "5.6k tweets",
  },
  {
    hashtag: "#fauci",
    posts: "8.2k tweets",
  },
];

const topMentions = [
  {
    mention: "@JoeBiden",
    posts: "5.9k tweets",
  },
  {
    mention: "@CNN",
    posts: "4.1k tweets",
  },
  {
    mention: "@CDC",
    posts: "7.5k tweets",
  },
  {
    mention: "@Fauci",
    posts: "6k tweets",
  },
];

const topDomains = [
  {
    domain: "cdc.gov",
    posts: "2.6k tweets",
  },
  {
    domain: "breitbart.com",
    posts: "1.6k tweets",
  },
  {
    domain: "cnn.com",
    posts: "9.2k tweets",
  },
  {
    domain: "infowars.com",
    posts: "3.7k tweets",
  },
];

const topRedditPosts = [
  {
    account: "u/jaymar01",
    title:
      "An Idaho health department isn’t allowed to give COVID vaccines anymore after a board vote. Their reason was they couldn't sign off on the shots being safe.",
    upvotes: "2.8k upvotes",
    community: "r/Coronavirus",
    embed: {
      url: "https://apnews.com/article/covid19-vaccine-public-health-idaho-76f1c29bf3f07a2c029175bf6c2180c4",
      image:
        "https://dims.apnews.com/dims4/default/d960189/2147483647/strip/true/crop/4120x2745+0+1/resize/980x653!/format/webp/quality/90/?url=https%3A%2F%2Fassets.apnews.com%2F3a%2Fb8%2Ff43f1c17598464028916a00b8d0a%2F70e9d71e20bc459da346b98e605f7112",
    },
    topComments: [
      {
        account: "u/Rassayana_Atrindh",
        upvotes: "988",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/a45fd68a-b12d-4bb6-941d-302a4d418a56.png",
        comment:
          "If the FDA has decided they're safe, and people want the vaccines, why is a county health department standing in the way? As a Montana resident, c'mon over, we're still free over here! I got my flu and covid vaccines a month ago from the local hospital.",
      },
      {
        account: "u/udlose",
        upvotes: "365",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/9faede63-6b76-4ab0-8132-92f638c7d48d.png",
        comment:
          "This is what happens when you vote for stupid motherfuckers. You have 48 hours to ponder this.",
      },
      {
        account: "u/GuardianCmdr",
        upvotes: "139",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfZWI5NTlhNzE1ZGZmZmU2ZjgyZjQ2MDU1MzM5ODJjNDg1OWNiMTRmZV8yMTk3MDMwMQ_rare_ec0127e9-7ec6-4ec8-8325-be627bf10b69.png",
        comment: "Death from covid isn't safe either, Morons.",
      },
    ],
  },
  {
    account: "u/zsreport",
    title:
      "Bird flu is getting worse. Disease experts worry Trump and RFK Jr. will mangle response like COVID",
    upvotes: "8.0k upvotes",
    community: "r/politics",
    embed: {
      url: "https://www.salon.com/2024/11/27/bird-flu-is-getting-worse-disease-experts-worry-and-rfk-jr-will-mangle-response-like/",
      image:
        "https://mediaproxy.salon.com/width/1200/https://media2.salon.com/2024/11/robert_f_kennedy_jr_dairy_cows_2185179002_2186078892.jpg",
    },
    topComments: [
      {
        account: "u/bosgeest",
        upvotes: "682",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/54ba4525-9075-4362-949c-e1cccef8d083.png",
        comment:
          "If this becomes a pandemic, the U.S. response will be so much worse. The culture war on masks and vaccines Republicans started partway through last time will be there from the start now, and an anti vaxxer is in charge now.\n\nSome consolation to me is that the best and quickest vaccines came from Europe last time and probably will this time. So Republicans can't really fuck up the development of the vaccine.\n\nIdiots will prevent vaccinations from protecting a large enough part of the population (again) though, so it will be a shit show again.\n\nLet's hope it blows over like most of these viruses.",
      },
      {
        account: "u/zsreport",
        upvotes: "2.6k",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/7072d979-6af5-46cc-820c-2bf63d1b06a9.png",
        comment: "Narrator: They'll mangle the fuck out of the response.",
      },
      {
        account: "u/bondbird",
        upvotes: "746",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/b78dff4e-b3e0-46f8-8bdc-e0a960fb9737.png",
        comment:
          "Second term - second pandemic??? When will we see the Plague of Frogs and Locust?",
      },
    ],
  },
  {
    account: "u/AintMuchToDo",
    title:
      "You need to prepare for the collapse of the US emergency medical system.",
    upvotes: "36k upvotes",
    community: "r/economicCollapse",
    embed: null,
    topComments: [
      {
        account: "u/KnightRiderCS949",
        upvotes: "775",
        profile_picture:
          "https://styles.redditmedia.com/t5_c1qy2q/styles/profileIcon_f5rnnbev5dld1.PNG?width=256&height=256&frame=1&auto=webp&crop=256:256,smart&s=dd6d63dd0577dcf1346bc7b2edbcfa8256cb68f1",
        comment:
          "I'm a former EMS technician and this is spot on. I got the hell out because of the coming collapse and toxic culture it's creating.\n\nYou are brave for saying it out loud though. Mad respect.",
      },
      {
        account: "u/No-Investment-4494",
        upvotes: "597",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfNDE2OWZmNGQzMTUwMmNiMWQwOWI4NTA0YTAwYThhNjMyZmIwODQ4Zl80MDI2MjQx_rare_d99817ba-8c1f-4246-b5fb-03355ff7de45.png",
        comment:
          "You bring up some serious issues about how fragile our emergency healthcare system really is. With so much dependence on federal funding, cuts to programs like Medicaid and EMTALA could be devastating, especially for rural and low-income communities. The situations you described as ambulance delays, overcrowded ERs, and exhausted staff are a scary reality if things keep going this way.\n\nPeople often don’t realize that healthcare budget cuts aren’t just numbers they’re real-life impacts on people’s safety and well-being. We need to have an honest conversation about sustainable funding and support for healthcare workers before we hit a true crisis. Thanks for bringing attention to this; it’s a wake-up call we all need.",
      },
      {
        account: "u/BakedDadd",
        upvotes: "453",
        profile_picture:
          "https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfZWI5NTlhNzE1ZGZmZmU2ZjgyZjQ2MDU1MzM5ODJjNDg1OWNiMTRmZV8zMDYxNzE3OQ_rare_7efcc113-cc70-4551-95bd-ff7fcef3c4a5.png",
        comment: "that was a fucking harrowing read",
      },
    ],
  },
];

const topLinkedInPosts = [
  {
    account: "Amy M.",
    profile_picture:
      "https://media.licdn.com/dms/image/v2/D5635AQHa3TOOAnk1zA/profile-framedphoto-shrink_800_800/profile-framedphoto-shrink_800_800/0/1732047739416?e=1733853600&v=beta&t=wDmknoBmt2CcGKe7Hxx54jAWIGS75eSVjLHsaaHq4x0",
    post: "The language we use to talk about Covid matters. Having been heavily involved in Covid-related advocacy and policy efforts this year, I have seen firsthand how language can make or break progress toward meaningful change. I see Covid and Long Covid mentioned on LinkedIn more than any other social media platform. The language used is almost always harmful. Here's why.",
    likes: "4 likes",
    embed: {
      url: "https://www.linkedin.com/pulse/we-all-want-move-past-covid-whats-harm-saying-its-over-amy-mora-cpxp-cvtle/?trackingId=zceKd3m4ShiwToETZLoM5g%3D%3D",
      image:
        "https://media.licdn.com/dms/image/v2/D4E12AQGTS8mSHzG4gQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1733193495668?e=1738800000&v=beta&t=QPELRxQFOdGJyPZGBJlJrpKcj347dVkLI-8RwpIH4Pk",
    },
  },
  {
    account: "Monica Simpson",
    profile_picture:
      "https://media.licdn.com/dms/image/v2/C4D03AQGnoEFYARDV7Q/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1571185591871?e=1738800000&v=beta&t=nlU72LCJ8PriGKTqt0lKvl-Np7BhKshB9_gjbaXX-bQ",
    post: "Can we reverse the damage of post-COVID parenting burnout? I think so.",
    likes: "2 likes",
    embed: {
      url: "https://www.linkedin.com/pulse/parenting-burnout-post-covid-monica-simpson-c58dc/?trackingId=P%2FqKQJlrRzigF3x%2FXcqbUA%3D%3D",
      image:
        "https://media.licdn.com/media/AAYQAgQJAAgAAQAAAAAAAC_ry7IoqkL0RrSxbX1_Azw_eg.png",
    },
  },
  {
    account: "Elaine Hanford",
    profile_picture:
      "https://media.licdn.com/dms/image/v2/C5603AQFEThPO_Z3s0w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1578940107574?e=1738800000&v=beta&t=s1WuqGIWKGM9SYDqodT95d1s3uMXpTUgt4Mm_8HDk5o",
    post: "Oversight Committee Report on Covid Vaccines - did 'more harm than good'",
    likes: "4 likes",
    embed: {
      url: "https://www.linkedin.com/pulse/oversight-committee-report-covid-vaccines-did-more-harm-hanford-sevic/?trackingId=98norK1aTWO%2B0heGkCnjmg%3D%3D",
      image:
        "https://media.licdn.com/dms/image/v2/D5612AQHUAnN7EDS1NA/article-cover_image-shrink_423_752/article-cover_image-shrink_423_752/0/1733219889557?e=1738800000&v=beta&t=y6Jo5VcUfDZZRZciOpKIrtkc5lbRh2xXfokzDg4sjRM",
    },
  },
];

const NarrativesTab = () => {
  const [selected, setSelected] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("Last 24 hours");

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDateRangeSelection = (range) => {
    setSelectedDateRange(range);
    setIsDropdownOpen(false);
  };

  return (
    <TabContainer>
      <HeaderText>Top COVID-19 Narratives</HeaderText>
      <HeaderTabs>
        <SocialsTabs>
          {socialTabs.map((item, index) => (
            <SocialTab
              key={index}
              selected={selected === index}
              onClick={() => setSelected(index)}
            >
              <TabIcon icon={item.icon} />
              <TabText>{item.title}</TabText>
            </SocialTab>
          ))}
        </SocialsTabs>
        <DateDropdown
          ref={buttonRef}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {selectedDateRange}
          <TabIcon icon={faCaretDown} />
        </DateDropdown>
        {isDropdownOpen && (
          <DropdownMenu ref={dropdownRef}>
            <DropdownItem
              onClick={() => handleDateRangeSelection("Last 24 hours")}
            >
              Last 24 hours
            </DropdownItem>
            <DropdownItem
              onClick={() => handleDateRangeSelection("Last 7 days")}
            >
              Last 7 days
            </DropdownItem>
            <DropdownItem
              onClick={() => handleDateRangeSelection("Last 30 days")}
            >
              Last 30 days
            </DropdownItem>
          </DropdownMenu>
        )}
      </HeaderTabs>
      <SubheaderText>Top Posts ({selectedDateRange})</SubheaderText>
      {selected === 0 && (
        <>
          <PostsGrid>
            {topTwitterPosts.map((item, index) => (
              <PostContainer key={index}>
                <PostHeader>
                  <AccountIcon image={item.profile_picture} />
                  <AccountInfo>
                    <AccountTitle>{item.name}</AccountTitle>
                    <AccountHandle>{item.account}</AccountHandle>
                  </AccountInfo>
                </PostHeader>
                <PostBody>{item.post}</PostBody>
                <PostFooter>
                  <FooterSection>
                    <TabIcon icon={faHeart} />
                    <div>{item.likes}</div>
                  </FooterSection>
                  <FooterSection>
                    <TabIcon icon={faRetweet} />
                    <div>{item.retweets}</div>
                  </FooterSection>
                  <FooterSection>
                    <TabIcon icon={faCalendar} />
                    <div>{item.date}</div>
                  </FooterSection>
                </PostFooter>
              </PostContainer>
            ))}
          </PostsGrid>
          <SubheaderText>Trending Hashtags ({selectedDateRange})</SubheaderText>
          <DataGrid>
            {topHashtags.map((item, index) => (
              <DataContainer>
                <DataHeader>{item.hashtag}</DataHeader>
                <DataSubheader>{item.posts}</DataSubheader>
              </DataContainer>
            ))}
          </DataGrid>
          <SubheaderText>Top Mentions</SubheaderText>
          <DataGrid>
            {topMentions.map((item, index) => (
              <DataContainer>
                <DataHeader>{item.mention}</DataHeader>
                <DataSubheader>{item.posts}</DataSubheader>
              </DataContainer>
            ))}
          </DataGrid>
          <SubheaderText>Top Domains</SubheaderText>
          <DataGrid>
            {topDomains.map((item, index) => (
              <DataContainer>
                <DataHeader>{item.domain}</DataHeader>
                <DataSubheader>{item.posts}</DataSubheader>
              </DataContainer>
            ))}
          </DataGrid>
        </>
      )}

      {selected === 1 && (
        <RedditGrid>
          {topRedditPosts.map((item, index) => (
            <PostContainer key={index}>
              <PostHeader>
                <AccountInfo>
                  <AccountTitle>{item.account}</AccountTitle>
                  <AccountHandle>{item.community}</AccountHandle>
                </AccountInfo>
              </PostHeader>
              <PostBody>
                <PostLink
                  href={item.embed?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </PostLink>
                {item.embed && <PostEmbed src={item.embed.image} />}
              </PostBody>
              <PostFooter>
                <FooterSection>
                  <TabIcon icon={faCircleArrowUp} />
                  <div>{item.upvotes}</div>
                </FooterSection>
              </PostFooter>

              {item.topComments &&
                item.topComments.map((comment, idx) => (
                  <CommentContainer key={idx}>
                    <CommentHeader>
                      <AccountIcon image={comment.profile_picture} />
                      <div>{comment.account}</div>
                    </CommentHeader>
                    <CommentBody>{comment.comment}</CommentBody>
                    <CommentFooter>
                      <FooterSection>
                        <TabIcon icon={faCircleArrowUp} />
                        <div>{comment.upvotes} upvotes</div>
                      </FooterSection>
                    </CommentFooter>
                  </CommentContainer>
                ))}
            </PostContainer>
          ))}
        </RedditGrid>
      )}

      {selected === 2 && (
        <RedditGrid>
          {topLinkedInPosts.map((item, index) => (
            <PostContainer key={index}>
              <PostHeader>
                <AccountIcon image={item.profile_picture} />
                <AccountInfo>
                  <AccountTitle>{item.account}</AccountTitle>
                </AccountInfo>
              </PostHeader>
              <PostBody>{item.post}</PostBody>
              {item.embed && (
                <a href={item.embed.url}>
                  <PostEmbed src={item.embed.image} />{" "}
                </a>
              )}
              <PostFooter>
                <FooterSection>
                  <TabIcon icon={faThumbsUp} />
                  <div>{item.likes}</div>
                </FooterSection>
              </PostFooter>
            </PostContainer>
          ))}
        </RedditGrid>
      )}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const TabText = styled.div`
  font-size: 16px;
`;

const HeaderText = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const SubheaderText = styled.div`
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.typography.color};
`;

const SocialsTabs = styled.div`
  display: flex;
  gap: 10px;
`;

const HeaderTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const SocialTab = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  transition: 0.2s ease;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const DateDropdown = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  transition: 0.2s ease;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 180px;
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const PostLink = styled.a`
  color: #fff !important;
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
`;

const RedditGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
`;

const PostHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const AccountIcon = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountTitle = styled.div`
  font-weight: bold;
  color: #fff;
`;

const AccountHandle = styled.div`
  color: #fff;
`;

const PostBody = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.typography.color};
`;

const PostFooter = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.typography.color};
`;

const FooterSection = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const PostEmbed = styled.img`
  width: 100%;
  height: 300px;
  margin-top: 15px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  color: ${(props) => props.theme.typography.color};
`;

const CommentHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CommentBody = styled.div`
  font-size: 14px;
`;

const CommentFooter = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const DataGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -5px; /* Offset left margin to counter the gap */
  margin-right: -5px; /* Offset right margin to counter the gap */
  gap: 10px;
`;

const DataContainer = styled.div`
  width: calc(50% - 5px); /* Take up 50% of the width minus half the gap */
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 10px;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const DataHeader = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 16px;
  font-weight: 800;
`;

const DataSubheader = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 12px;
  font-weight: 600;
`;

export default NarrativesTab;
