import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../context/ModalContext";
import AddUserModal from "../components/Modals/AddUserModal";

const tabs = [
  {
    title: "Account",
    icon: faUser,
  },
  {
    title: "Organization",
    icon: faBuilding,
  },
  {
    title: "Billing",
    icon: faWallet,
  },
];

const initialUsers = [
  {
    id: 0,
    name: "Alex Swearingen",
    email: "alex.swearingen@astronomic.com",
    accepted: true,
  },
  { id: 1, name: "John Doe", email: "john.doe@example.com", accepted: true },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane.smith@example.com",
    accepted: false,
  },
  {
    id: 3,
    name: "Chris Johnson",
    email: "chris.johnson@example.com",
    accepted: true,
  },
];

const invoices = [
  {
    id: 0,
    date: "October 1, 2024",
    amountDue: "$100",
    paid: false,
  },
  {
    id: 1,
    date: "September 1, 2024",
    amountDue: "$100",
    paid: true,
  },
  {
    id: 2,
    date: "August 1, 2024",
    amountDue: "$100",
    paid: true,
  },
  {
    id: 3,
    date: "July 1, 2024",
    amountDue: "$100",
    paid: true,
  },
];

const AccountSettingsPage = () => {
  // useState for managing first name, last name, and the message
  const [selected, setSelected] = useState(0);
  const [firstName, setFirstName] = useState("Alex");
  const [lastName, setLastName] = useState("Swearingen");
  const [email] = useState("alex.swearingen@astronomic.com");
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const [hasChanged, setHasChanged] = useState(false);
  const [users, setUsers] = useState(initialUsers);
  const { openModal } = useModal();

  const handleOpenUserModal = () => {
    openModal(
      <AddUserModal addUser={addUser} />,
      "Invite User to Organization"
    );
  };

  const handleRemoveUser = (userId) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setHasChanged(true);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setHasChanged(true);
  };

  const handleResetPassword = () => {
    setPasswordResetMessage(
      "A password reset link has been sent to your email."
    );
  };

  const handleSaveChanges = () => {
    console.log("Saved Changes:", { firstName, lastName });
    setHasChanged(false);
  };

  const addUser = (firstName, lastName, email) => {
    const newId = users.length
      ? Math.max(...users.map((user) => user.id)) + 1
      : 1;
    let newUser = {
      name: firstName + " " + lastName,
      email: email,
      accepted: false,
    };
    setUsers([...users, { ...newUser, id: newId }]);
  };

  return (
    <PageContainer>
      <TabsContainer>
        {tabs.map((item, index) => (
          <Tab
            selected={selected === index}
            onClick={() => setSelected(index)}
            key={index}
          >
            <TabIcon icon={item.icon} />
            <TabText>{item.title}</TabText>
          </Tab>
        ))}
      </TabsContainer>
      {selected == 0 && (
        <TabContainer>
          <SettingRow>
            <UserCircle>AS</UserCircle>
            <div>
              <SettingHeader>
                {firstName} {lastName}
              </SettingHeader>
              <SettingSubheader>Pro Plan</SettingSubheader>
            </div>
          </SettingRow>
          <SettingRow>
            <FormText>First Name</FormText>
            <Input
              value={firstName}
              onChange={handleFirstNameChange}
              placeholder="First Name"
            />
          </SettingRow>
          <SettingRow>
            <FormText>Last Name</FormText>
            <Input
              value={lastName}
              onChange={handleLastNameChange}
              placeholder="Last Name"
            />
          </SettingRow>
          <SettingRow>
            <FormText>Email</FormText>
            <FormSmallText>{email}</FormSmallText>
          </SettingRow>
          <SettingRow>
            <FormText>Password</FormText>
            <Button onClick={handleResetPassword}>Reset Password</Button>
          </SettingRow>
          {passwordResetMessage && (
            <SettingRow>
              <FormSmallText>{passwordResetMessage}</FormSmallText>
            </SettingRow>
          )}
          {hasChanged && (
            <SettingRow>
              <Button onClick={handleSaveChanges}>Save Changes</Button>
            </SettingRow>
          )}
        </TabContainer>
      )}
      {selected == 1 && (
        <TabContainer>
          <OrganizationHeader>
            <SettingHeader>Antibionic</SettingHeader>
            <Button onClick={handleOpenUserModal}>Add User</Button>
          </OrganizationHeader>
          <TableContainer>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Accepted</TableHeaderCell>
                  <TableHeaderCell>Actions</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Status
                        icon={user.accepted ? faCircleCheck : faCircleXmark}
                      />
                    </TableCell>
                    <TableCell>
                      <RemoveButton onClick={() => handleRemoveUser(user.id)}>
                        Remove
                      </RemoveButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabContainer>
      )}
      {selected == 2 && (
        <TabContainer>
          <SettingHeader>Billing</SettingHeader>
          <SettingRow>
            <BillingContainer>
              <BillingHeader>Overview</BillingHeader>
              <BillingRow>
                <BillingText>Billing Cycle</BillingText>
                <BillingText>Monthly</BillingText>
              </BillingRow>
              <BillingRow>
                <BillingText>Next Billing Date</BillingText>
                <BillingText>01-01-2025</BillingText>
              </BillingRow>
              <BillingRow>
                <BillingText>Renewal Date</BillingText>
                <BillingText>01-01-2025</BillingText>
              </BillingRow>
            </BillingContainer>
            <BillingContainer>
              <BillingHeader>Payment Method</BillingHeader>
              <BillingRow>
                <BillingText>Payment Method</BillingText>
                <BillingText>●●●●●●●● 1234</BillingText>
              </BillingRow>
              <BillingRow>
                <BillingText>Name on Card</BillingText>
                <BillingText>Alex Swearingen</BillingText>
              </BillingRow>
              <BillingRow>
                <BillingText>Payment Method</BillingText>
                <BillingText>Expires 12/2027</BillingText>
              </BillingRow>
            </BillingContainer>
          </SettingRow>
          <SettingHeader>Invoices</SettingHeader>
          <TableContainer>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Invoice Date</TableHeaderCell>
                  <TableHeaderCell>Amount Due</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.date}</TableCell>
                    <TableCell>{invoice.amountDue}</TableCell>
                    <TableCell>
                      <Status
                        icon={invoice.paid ? faCircleCheck : faCircleXmark}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabContainer>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const TabsContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

const TabIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  width: 30px;
`;

const Tab = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.background};
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  transition: 0.2s ease;
  cursor: pointer;
  border: 1px solid #ccc;
  color: ${(props) => props.theme.typography.color};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const TabText = styled.div`
  padding-top: 4px;
  font-size: 16px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0% 5%;
  min-width: 500px;
`;

const UserCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: ${(props) => props.theme.colors.primary};
  border: 1px solid #ccc;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
`;

const SettingHeader = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.typography.color};
  font-weight: 800;
`;

const SettingSubheader = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.typography.color};
`;

const SettingRow = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FormText = styled.div`
  width: 100px;
  font-size: 12px;
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const FormSmallText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.typography.color};
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-bottom: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const OrganizationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  border: 1px solid #ccc;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;

const TableHeaderCell = styled.th`
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
  color: ${(props) => props.theme.typography.color};
  font-weight: bold;
`;

const TableCell = styled.td`
  padding: 12px 15px;
  font-size: 14px;
  color: ${(props) => props.theme.typography.color};
`;

const TableBody = styled.tbody``;

const Status = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.typography.color};
  font-size: 20px;
`;

const RemoveButton = styled.button`
  padding: 8px 15px;
  background-color: ${(props) => props.theme.colors.red};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }
`;

const BillingContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 15px;
  gap: 20px;
`;

const BillingHeader = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: ${(props) => props.theme.typography.color};
`;

const BillingRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BillingText = styled.div`
  color: ${(props) => props.theme.typography.color};
  font-size: 12px;
`;

export default AccountSettingsPage;
