import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length && password.length) {
      navigate("/dashboard");
    } else {
      setErrorMessage("Please enter a valid email and password.");
    }
    console.log("Logged in with:", { email, password });
  };

  return (
    <LoginContainer>
      <Form onSubmit={handleSubmit}>
        <Logo light />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit">Login</Button>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <p>
          Don't have an account?{" "}
          <Link to="/register" style={{ color: "#fff" }}>
            Register
          </Link>
        </p>
      </Form>
    </LoginContainer>
  );
};

export default LoginPage;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  gap: 20px;
  color: ${(props) => props.theme.typography.color};
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-bottom: 10px;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const ErrorText = styled.div`
  font-size: 16px;
  color: #f00;
`;
