import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      console.log("User registered with:", { email, password });
    } else {
      alert("Passwords don't match!");
    }
  };

  return (
    <RegisterContainer>
      <Form onSubmit={handleSubmit}>
        <FormHeader>
          <Logo light />
          {/* <h2>Register</h2> */}
        </FormHeader>

        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button type="submit">Register</Button>
        <p>
          Already have an account?{" "}
          <Link to="/" style={{ color: "#fff" }}>
            Login
          </Link>
        </p>
      </Form>
    </RegisterContainer>
  );
};

export default RegisterPage;

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  gap: 20px;
  color: ${(props) => props.theme.typography.color};
`;

const FormHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  margin-bottom: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;
